/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "스탠다드아웃 100번째 로그에서는 아웃사이더 님을 모시고 100회 특집, 아이폰 12, 하시코프의 새 서비스 등에 대해서 이야기를 나누었습니다."), "\n", React.createElement(_components.h3, null, "100회 특집"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://sduck4.github.io/stdout.fm-100th/"
  }, "Sduck님이 보내주신 축전: 스탠다드아웃 100회 기념, 지난 에피소드 돌아보기")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://marpple.shop/kr/44bits"
  }, "100회 기념 굿즈: 후드, 컵, 휴대폰 케이스")), "\n"), "\n", React.createElement(_components.h3, null, "아이폰 12, 홈팟 미니 출시"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/kr/iphone-12/"
  }, "아이폰 12")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/homepod-mini/"
  }, "홈팟 미니")), "\n"), "\n", React.createElement(_components.h3, null, "하시코프의 새 서비스들"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hashicorp.com/blog/hashicorp-boundary"
  }, "Boundry")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hashicorp.com/blog/announcing-waypoint"
  }, "Waypoint")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hashicorp.com/blog/vault-on-the-hashicorp-cloud-platform"
  }, "Vault cloud")), "\n"), "\n", React.createElement(_components.h3, null, "파이썬 3.9 출시"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.python.org/downloads/release/python-390/"
  }, "공식 릴리스 노트")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/python-3-9-release-note-summary"
  }, "파이썬 3.9 릴리스와 주요 변경 사항 ")), "\n"), "\n", React.createElement(_components.h3, null, "MDN 브라우저 호환성 보고서"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://mdn-web-dna.s3-us-west-2.amazonaws.com/MDN-Browser-Compatibility-Report-2020.pdf"
  }, "MDN Browser Compatibility Report")), "\n"), "\n", React.createElement(_components.h3, null, "stdout.fm 리브랜딩"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
